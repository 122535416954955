<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table 
                    striped
                    small
                    id="myTables"
                    bordered
                    selectable
                    selected-variant="primary"
                    select-mode="single"
                    responsive="sm"
                    :items="drivers"
                    :fields="fields"
                    head-row-variant="primary"
                    >
                    <template #cell(checkbox)="row">
                      <b-form-checkbox
                      v-model="row.detailsShowing"
                      plain
                      class="vs-checkbox-con"
                      @change="row.toggleDetails"
                      >
                      </b-form-checkbox>
                  </template>  
                    
                </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.css'

export default {
    data() {
        return {
            id: this.$route.query.driver_id,
            drivers: [],
            fields: [
            { key: 'checkbox', label: '', thStyle: {width: '30px'}},
            { key: 'performer_id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
            { key: 'order_id', label: 'Id заказа', sortable: true, thStyle: {width: '70px'}},
            { key: 'performer_login', label: 'Логин', sortable: true, thStyle: {width: '70px'}},
            { key: 'amount', label: 'Количество', sortable: true, thStyle: {width: '150px'}},
            { key: 'category', label: 'Категория', sortable: true, thStyle: {width: '150px'}},
            { key: 'created_at', label: 'Создание', sortable: true, thStyle: {width: '150px'} },
            { key: 'sum_after', label: 'Сумма до', sortable: true, thStyle: {width: '150px'} },
            { key: 'sum_for', label: 'Сумма после', sortable: true, thStyle: {width: '150px'} },
            { key: 'type_name', label: 'Имя типа', sortable: true, thStyle: {width: '150px'} },
          ]
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.resizeable(),
        this.getDriverAction()
      
    },
    methods: {
        resizeable(){
            var thElm;
            var startOffset;
            Array.prototype.forEach.call(
            document.querySelectorAll("table th"),
            function (th) {
                th.style.position = 'relative';
                var grip = document.createElement('div');
                grip.innerHTML = "&nbsp;";
                grip.style.top = 0;
                grip.style.right = 0;
                grip.style.bottom = 0;
                grip.style.width = '5px';
                grip.style.position = 'absolute';
                grip.style.cursor = 'col-resize';
                grip.addEventListener('mousedown', function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                });
                th.appendChild(grip);
            });
            document.addEventListener('mousemove', function (e) {
                if (thElm) {
                    thElm.style.width = startOffset + e.pageX + 'px';
                }
            });
            document.addEventListener('mouseup', function () {
                thElm = undefined;
            });
        },
        getDriverAction() {
            this.$http
            .get(`documents/${this.id}/performers-transactions`)
            .then(res => {
                this.drivers = res.data
                this.$store.commit('pageData/setdataCount', this.drivers.length)
            })
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }  
.my-danger{
    background-color: #f9cfcf;
}  
.my-success{
    background-color: #8fe2b4;
} .my-secondary{
    background-color: #dcdddf;
} .my-warning{
    background-color: #ffcd9d;
}   
</style>